import React from 'react'
import {
  SidebarEmailIcon,
  SidebarProfileIcon,
  SidebarScrumBoardIcon,
  SidebarEcommerceIcon,
  SidebarTablesIcon,
  SidebarInvoiceIcon,
  SidebarUIIcon,
  SidebarToDosIcon,
  SidebarAdvancedIcon,
  SidebarFireStoreIcon,
  SidebarMapsIcon,
  SidebarContactsIcon
} from '@iso/config/icon.config'

import { RiHandCoinFill, RiMoneyDollarBoxFill } from 'react-icons/ri'

const options = [
  {
    key: '/dashboard/products',
    label: 'products',
    leftIcon: <SidebarEcommerceIcon size={19} />
  },
  {
    key: '/dashboard/inventories',
    label: 'inventories',
    leftIcon: <SidebarToDosIcon size={19} />
  },
  {
    key: '/dashboard/orders',
    label: 'orders',
    leftIcon: <SidebarInvoiceIcon size={19} />
  },
  {
    key: '/dashboard/withdraws',
    label: 'withdraws',
    leftIcon: <RiHandCoinFill size={19} />
  },
  {
    key: '/dashboard/wallets',
    label: 'wallets',
    leftIcon: <RiMoneyDollarBoxFill size={19} />
  },
  {
    key: '/dashboard/slides',
    label: 'slides',
    leftIcon: <SidebarFireStoreIcon size={19} />
  }
]
export default options
